.sidebar {
  -webkit-transition: -webkit-transform 0.5s;
  transition: transform 0.5s;
}
.is-active .spanITem {
  background: var(--color-primary);

  color: var(--color-secondary);
  font-weight: 500;
  // transition: all .1s;
  &:hover {
    background: var(--color-primary);
  }
}
.is-active-sidebar {
  width: 100%;
}
.is-active-sidebar svg path {
  fill: var(--color-secondary);
}

.is-active-sidebar .active-sidebar {
  // background: var(--color-primary);
  // border-right: 3px solid var(--color-primary);
  // border-radius: 0px;
  background-color: var(--color-primary);
}

.is-active-profile {
  background-color: rgb(255, 255, 255);
}

.is-active-profile path {
  fill: rgba(64, 148, 247, 1);
  padding: 11px;
}
