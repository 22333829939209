@media print {
  .cheque {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .container {
    text-align: center;
  }

  .h1 {
    font-weight: 500;
    font-size: 0.8rem;
    line-height: 1.75rem;
  }

  .h2 {
    font-weight: 500;
    font-size: 0.7rem;
    line-height: 1.75rem;
  }

  .number {
    font-weight: 500;
    font-size: 0.65rem;
    line-height: 1rem;
  }

  .footer {
    font-weight: 700;
    font-size: 0.55rem;
    line-height: 1rem;
  }

  .item {
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-size: 0.6rem;
  }
}

@page {
  margin: 0.5cm;
}
