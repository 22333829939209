.upload {
  box-sizing: border-box;
  position: relative;

  .dropzone {
    width: 100px;
    cursor: pointer;
    position: static;
    height: 100px;
    left: 0px;
    top: 0px;
    box-sizing: border-box;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    margin: 0.5rem;
  }
}
.imgContent {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin: 0.5rem;

  img {
    width: 100px;
    height: auto;
  }

  .imgButtons {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    width: 100px;
    height: 100px;
    background-color: rgba(0, 0, 0, 0);
    opacity: 0;
    transition-duration: 0.5s;
    cursor: pointer;
  }

  &:hover {
    .imgButtons {
      background-color: rgba(0, 0, 0, 0.4);
      opacity: 1;
      transition-duration: 0.5s;
    }
  }
}
