.custom-radio {
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;

    &:checked {
      & ~ .checkmark-radio {
        background-color: #2196f3;
        border-color: #2196f3;

        &:after {
          display: block;
        }
      }
    }
    &:disabled ~ .checkmark-radio {
      background-color: #eee;
      border-color: rgba(176, 186, 191, 1);
    }
    &:focus ~ .checkmark-radio {
      box-shadow: 0 0 0 4px rgba(21, 156, 228, 0.4);
    }
  }

  .checkmark-radio {
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(246, 248, 249, 1);
    border: 1px solid #b0babf;
    border-radius: 50%;

    &:after {
      content: "";
      position: absolute;
      display: none;
      background: white;
      border-radius: 50%;
    }
  }

  // &:hover {
  //     input ~ .checkmark-radio {
  //         background-color: white;
  //         border-color: rgba(176, 186, 191, 1);
  //     }
  // }

  &.error {
    input:checked ~ .checkmark-radio {
      border: 2px solid rgba(242, 39, 28, 1);
    }
    .checkmark-radio {
      border: 2px solid rgba(242, 39, 28, 1);
    }
  }

  &.disabled {
    color: rgba(176, 186, 191, 1);
    cursor: unset;
  }

  &_large {
    padding-left: 35px;
    margin-bottom: 12px;
    font-size: 16px;

    &.error {
      .checkmark-radio:after {
        top: 4px;
        left: 4px;
      }
    }

    .checkmark-radio {
      height: 20px;
      width: 20px;

      &:after {
        top: 5px;
        left: 5px;
        width: 8px;
        height: 8px;
      }
    }
  }

  &_medium {
    padding-left: 30px;
    margin-bottom: 12px;
    font-size: 14px;

    &.error {
      .checkmark-radio:after {
        top: 3px;
        left: 3px;
      }
    }

    .checkmark-radio {
      height: 16px;
      width: 16px;

      &:after {
        top: 4px;
        left: 4px;
        width: 6px;
        height: 6px;
      }
    }
  }

  &_small {
    padding-left: 25px;
    margin-bottom: 12px;
    font-size: 12px;

    &.error {
      .checkmark-radio:after {
        top: 2px;
        left: 2px;
      }
    }

    .checkmark-radio {
      height: 12px;
      width: 12px;
      margin-top: 2px;

      &:after {
        top: 3px;
        left: 3px;
        width: 4px;
        height: 4px;
      }
    }
  }
}
