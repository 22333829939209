.upload {
  box-sizing: border-box;
  position: relative;

  .dropzone {
    width: 100%;
    cursor: pointer;
    position: static;
    height: 164px;
    left: 0px;
    top: 0px;
    border: 1px dashed #d5dadd;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .img-content {
    /* width: 100%; */
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;

    img {
      max-width: 100%;
      max-height: 95%;
      width: auto;
    }

    .img-buttons {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 1;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0);
      opacity: 0;
      transition-duration: 0.5s;
    }

    &:hover {
      .img-buttons {
        background-color: rgba(0, 0, 0, 0.4);
        opacity: 1;
        transition-duration: 0.5s;
      }
    }
  }
}
