:root {
  --color-primary: #703581;
  --color-secondary: #eeeeee;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Inter", "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scrollbar-width: none; /* for Firefox */
}
body::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.dashboard_list li {
  cursor: pointer;
  user-select: none;
}

.trnns-group {
  transition: all 0.3s;
}

::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background: #703581;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #a455ba;
}
input {
  outline: 0;
  background-color: transparent;
  width: 100%;
  transition: all 0.3s;
}

input:focus {
  opacity: 0.7;
}

input[type="checkbox"],
input[type="radio"] {
  width: unset;
}
#inputmask{
  padding: 8px 10px;
  height: 33px;
  color: #6c6c74;
  /* margin-top: 12px; */
  border: 1px solid #e5e9eb;
  border-radius: 6px;

}

.rc-calendar-header .rc-calendar-prev-month-btn:after {
  content: '\003C' !important;
}
.rc-calendar-header .rc-calendar-next-month-btn:after {
  content: '\003E' !important;
}

.rc-calendar-header .rc-calendar-next-year-btn:after {
  content: '\226B' !important;
}

.rc-calendar-header .rc-calendar-prev-year-btn:after {
  content: '\226A' !important;
}