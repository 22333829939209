.title {
  text-transform: capitalize;
  color: rgb(91, 104, 113);
  display: flex;
  align-items: center;
}
.table_row td {
  &:nth-child(1) {
    position: sticky;
    left: -1px;
    background: #fff;
  }
}
.table_head {
  background: #fff;
  position: sticky;
  top: -3px;
  z-index: 50;
}
.table_row th {
  &:nth-child(1) {
    position: sticky;
    left: -1px;
    background: #fff;
    top: 0;
  }
}
.box {
  :nth-child(1) {
    position: relative;
    margin-left: 26px;
    font-size: 14px;
    line-height: 20px;
    color: #303940;
    &::before {
      content: "";
      width: 20px;
      height: 20px;
      background: #22c348;
      position: absolute;
      left: -25px;
      border-radius: 6px;
    }
  }
  :nth-child(2) {
    position: relative;
    margin-left: 26px;
    font-size: 14px;
    line-height: 20px;
    color: #303940;
    &::before {
      content: "";
      width: 20px;
      height: 20px;
      background: #0e73f6;
      position: absolute;
      left: -25px;
      border-radius: 6px;
    }
  }
  :nth-child(3) {
    position: relative;
    margin-left: 26px;
    font-size: 14px;
    line-height: 20px;
    color: #303940;
    &::before {
      content: "";
      width: 20px;
      height: 20px;
      background: #f2271c;
      position: absolute;
      left: -25px;
      border-radius: 6px;
    }
  }
}
